
import { createUserCourseNote, UserCourseNote } from 'momai'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { fetchNote, addNote } from '@/api/entity/course'
import { getUserId } from '@/utils/auth'
import { Toast } from 'vant'

export default {
  setup: () => {
    const { courseId } = useRoute().params
    const myNote = ref<UserCourseNote>(createUserCourseNote({
      courseId: Number(courseId),
      userId: Number(getUserId())
    }))
    const fetch = () => {
      fetchNote(Number(courseId)).then(c => {
        if (c) {
          myNote.value = c
        }
      })
    }

    fetch()

    return {
      myNote,
      doAdd: () => {
        addNote(myNote.value).then(() => {
          Toast.success('笔记保存成功！')
          fetch()
        })
      }
    }
  }
}
